@import "../../assets/less/_variables.less";


.no-orders {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ScrollbarsCustom {
	&.luqidation-scroll{
		height: calc(100vh - 322px) !important;
		@media screen and (max-width:1440px) {
			height: calc(100vh - 300px) !important;
		}
		@media screen and (max-width:1366px) {
            height: calc(100vh - 280px) !important;
        }
	}
}