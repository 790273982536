@import "../../assets/less/_variables.less";

.main-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	top: 7.5rem;
	position: relative;
	padding: 4rem;
	border-radius: 2rem;
	margin: auto;
	background: linear-gradient(
		270.08deg,
		rgba(132, 214, 164, 0.3) 0.02%,
		rgba(18, 28, 32, 0.3) 117.26%
	);
	width: 50vw;
	height: 40vh;

	.header {
		color: white;
		font-size: 32px;
	}
	.connect-btn {
		color: black;
		cursor: pointer;
		background-color: white;
		border-radius: 12px;
		padding: 1rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		.metamask-icon {
			margin-right: 1rem;
			width: 1.5rem;
		}
	}
	.firefox {
		position: absolute;
		right: -7.5rem;
		bottom: -7.5rem;
		@media @desktop {
			width: 20rem;
		}
		@media @upper {
			width: 25rem;
		}
		@media screen and (max-width:1440px) {
			width: 20rem;
		}
		@media screen and (max-width:1366px) {
			width: 18rem;
		}
	}
}

.closeIcon {
	margin-top: 1rem;
}

.ant-notification-notice-with-icon {
	display: grid;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
}
