.mainContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 100vh;
}

.lend-logo {
	animation: pulse 3s infinite;
	animation-delay: 0s;
}

@keyframes pulse {
	0% {
		opacity: 0.2;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
}

@-o-keyframes pulse {
	0% {
		opacity: 0.2;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
}

@-moz-keyframes pulse {
	0% {
		opacity: 0.2;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
}

@-webkit-keyframes pulse {
	0% {
		opacity: 0.2;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
}
