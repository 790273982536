@import "./_variables.less";

html,
body {
	min-height: 100vh;
	font-family: @font-family;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	font-smoothing: antialiased !important;
	font-feature-settings: normal !important;
	font-variant: normal !important;
	font-size: @font-size-base + 2;
	scroll-behavior: smooth;
	padding: 0;
	margin: 0;
	background: @secondary-color;
	color: @text-white;
}
main {
	position: relative;
}
a {
	text-decoration: none;
}
.icon {
	width: 16px;
	height: auto;
	fill: #000;
	transition: fill 0.3s;
	&.icon-white {
		fill: #fff;
	}
}

#root {
	overflow-x: hidden;
}

.anchor-tag {
	cursor: pointer;
}

h1 {
	color: @text-color-primary;
	font-size: 2rem;
	font-weight: bold;
	@media screen and (max-width:1440px) {
		font-size: 24px;
	}
	@media screen and (max-width:1366px) {
		font-size: 20px;
	}
}

h2 {
	color: @text-color-primary;
	font-weight: bold;
	@media screen and (max-width:1440px) {
		font-size: 18px;
	}
	@media screen and (max-width:1280px) {
		font-size: 16px;
	}
}

h3 {
	font-size: @font-size-xlg + 2;
	color: @text-color-primary;
	font-weight: 600;
}

h4 {
	color: @text-color-primary;
}

p {
	font-size: @font-size-base + 2;
	color: @subtext-color;
}

b {
	color: @text-color-primary;
}

.ant-btn {
	&.ant-btn-primary {
		background: @primary-color;
		border: 1px solid @border-color-base;
		color: @black;
		border-radius: 4px;
	}
	&.ant-btn-secondary {
		background: @btn-gradient;
		height: 40px;
		line-height: 40px;
		padding: 0 20px;
		color: @secondary-color;

		font-weight: bold;
		&.ant-btn-lg {
			height: 50px;
			line-height: 50px;
			padding: 0 30px;
		}
	}
}

.ant-radio-wrapper {
	font-size: 13px;
	font-weight: normal;
	color: @text-white;
	.ant-radio {
		.ant-radio-inner {
			border-color: @border-dark-green;
			background-color: @border-dark-green;
		}
		&.ant-radio-checked {
			.ant-radio-inner {
				&::after {
					background: @success-color;
				}
			}
		}
	}

	.ant-radio-disabled + span {
		color: rgba(255, 255, 255, 0.5);
		cursor: not-allowed;
	}
}

.ant-input {
	height: 40px;
	line-height: 40px;
	background-color: @select-bg-secondary;
	color: @text-white;
	border: 1px solid @border-dark-green;
	border-radius: 6px;
	&:focus {
		border-color: @success-color;
	}
}

.ant-input-disabled {
	//   background-color: @select-bg-secondary !important;
	// 	color: white;
	//   border: 1px solid @border-dark-green;
	//   border-radius: 6px;
}

.ant-select {
	&:not(.ant-select-customize-input) {
		.ant-select-selector {
			height: 40px;
			line-height: 40px;
			background-color: @select-bg-secondary;
			color: @text-white;
			border: 1px solid @border-dark-green;
			border-radius: 6px;
			@media screen and (max-width:1366px) {
				height: 36px;
				line-height: 36px;
			}
			input {
				height: 40px !important;
				line-height: 100%;
				@media screen and (max-width:1366px) {
					height: 36px !important;
				}
			}
			.ant-select-selection-item {
				font-size: @font-size-base - 1;
				line-height: 38px;
				color: @text-white;
				@media screen and (max-width:1366px) {
					line-height: 36px;
					font-size: @font-size-base - 2;
				}
			}
			.ant-select-selection-placeholder {
				line-height: 38px;
				color: @text-white;
				@media screen and (max-width:1366px) {
					line-height: 36px;
					font-size: @font-size-base - 2;
				}
			}
		}
		&:hover,
		&:focus {
			.ant-select-selector {
				border-color: @success-color !important;
				box-shadow: none !important;
			}
		}
		.ant-select-arrow {
			.icon {
				width: 10px;
				fill: @text-white;
			}
		}
	}
	&.ant-select-disabled{
		pointer-events: none;
	}
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
	color: rgba(0, 0, 0, 0.1);
	background: #192229;
	cursor: not-allowed;
}

.ant-select-dropdown {
	&.capx-dropdown {
		background: @card-bg;
		padding: 3px;
		border: 1px solid @border-dark-green;
		border-radius: 0 0 16px 16px;
		.ant-select-item {
			font-weight: normal;
			color: @text-white;
			border-radius: 4px;
			&.ant-select-item-option-active,
			.ant-select-item-option-selected {
				background: #1d282f;
			}
			.ant-select-item-option-content{
				@media screen and (max-width:1366px) {
					font-size: @font-size-base - 2;
				}
			}
		}
	}
}

.main-content {
	//hidden below 1920*1080
	@media (max-width: 1279px) {
		display: none;
	}
	.capx-sider{
		&.ant-layout-sider {
			background: @sidebar-bg;
			&.sidebar-open{
				width: 100%;
				max-width: 300px;
				width: 300px;
				min-width: 300px;
			}
		}
	}
	.right-content-wrapper {
		min-height: calc(100vh - 95px);
		background: none;
		padding: 15px 45px 0 45px;
	}
}

.breakpoint {
	//visible below 1920*1080
	@media (min-width: 1279px) {
		display: none;
		width: 100%;
	}
	.content-wrapper {
		min-height: calc(100vh - 95px);
		background: none;
		padding: 15px 45px 0 45px;
	}
}

.ant-layout {
	background: none;
	.ant-layout-footer {
		&.main-footer {
			position: sticky;
			color: #f1faf2;
			opacity: 0.5;
			background-color: @secondary-color;
			border-top: 2px solid #4d4d4d;
			text-align: center;
			padding: 5px;
			height: 30px;
		}
	}
}

.capx-card {
	background: @card-gradient;
	border: 1px solid @border-color-base;
	padding: 25px;
	border-radius: 1rem;
	margin-bottom: 15px;
}

.capx-card-secondary {
	background: @card-bg;
	border: 1px solid @border-card;
	padding: 15px 25px;
	border-radius: 1rem;
	margin-bottom: 15px;
}

.ant-checkbox-wrapper {
	color: @text-white;
	.ant-checkbox {
		top: 0.32em;
		.ant-checkbox-inner {
			border-color: @border-dark-green;
			width: 18px;
			height: 18px;
			@media screen and (max-width:1280px) {
				width: 16px;
				height: 16px;
			}
			&::after {
				top: 52%;
				left: 24.5%;
				@media screen and (max-width:1280px) {
					top: 48%;
				}
			}
		}
		&.ant-checkbox-checked {
			.ant-checkbox-inner {
				background-color: @white;
				&::after {
					border-color: @border-dark-green;
				}
			}
		}
	}

	.ant-checkbox-disabled + span {
		color: rgba(255, 255, 255, 0.5);
		cursor: not-allowed;
	}
}

.ant-slider-tooltip {
	padding-bottom: 0;
	.ant-tooltip-content {
		.ant-tooltip-inner {
			background-color: @success-color;
			color: @secondary-color;
			font-size: 14px;
			font-weight: 600;
			padding: 0 8px;
			height: 20px;
			line-height: 20px;
			min-height: 20px;
			border-radius: 1px;
		}
		.ant-tooltip-arrow-content {
			display: none;
		}
	}
}

.ant-slider-disabled .ant-slider-rail {
	background-color: #b3b3b3 !important;
}

.disable-class{
	pointer-events: none;
}

.filter-container {
	display: flex;
	.select-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 16px;

		.ant-select{
			width: 180px;
			@media screen and (max-width:1366px) {
				width: 140px;
			}
			.ant-select-selector{
				width: 95%;
			}
			.ant-select-selection-item{
				font-size: @font-size-base - 1;
				@media screen and (max-width:1366px) {
					font-size: @font-size-base - 3;
				}
			}
		}

		.capx-dropdown {
			width: 10px;
			&:hover {
				border-color: none !important;
				box-shadow: none !important;
			}
		}
		p {
			margin-bottom: 0;
			@media screen and (max-width:1366px) {
				font-size: 12px;
			}
		}
	}
}


.capx-modal{
	&.capx-modal-loading{
		.ant-modal-content{
			background: radial-gradient(143.33% 143.33% at 82.41% -4.96%, rgba(132, 214, 164, 0.3) 0%, rgba(18, 28, 32, 0.3) 100%);
			border-radius: 20px;
			text-align: center;
			box-shadow: none;
			max-width: 480px;
			.ant-modal-body{
				padding: 24px 60px;
			}
			p{
				font-size: @font-size-sm + 1;
			}
			.modal-icon{
				.icon{
					width: 100px;
				}
			}
			h4{
				color: @text-white;
				font-size: 22px;
				font-weight: bold;
			}
		}
		.ant-modal-footer{
			border-color: @border-card;
		}
	}
	&.nodata-modal{
		.ant-modal-content{
			background: radial-gradient(143.33% 143.33% at 82.41% -4.96%, rgba(132, 214, 164, 0.3) 0%, rgba(18, 28, 32, 0.93) 100%);
			border-radius: 20px;
			text-align: center;
			box-shadow: none;
			max-width: 480px;
			.ant-modal-body{
				padding: 24px 60px;
			}
			p{
				font-size: @font-size-base + 2;
			}
			.modal-icon{
				.icon{
					width: 70px;
					fill: @warning-color;
				}
				margin-bottom: 10px;
			}
			h4{
				color: @text-white;
				font-size: 22px;
				font-weight: bold;
			}
		}
		.ant-modal-footer{
			border-color: @border-card;
		}
	}
}

.ScrollbarsCustom-TrackY{
	background: @card-bg !important;
	width: 6px !important;
	.ScrollbarsCustom-ThumbY{
		background: @tab-gradient !important;
	}
}

.ScrollbarsCustom-TrackX{
	background: @card-bg !important;
	height: 6px !important;
	.ScrollbarsCustom-ThumbX{
		background: @tab-gradient !important;
	}
}

.capx-tabs{
	.mainContainer{
		min-height: 70vh;
	}
}

::-webkit-scrollbar {
	width: 6px;
	border-radius: 6px;
  }
  
  ::-webkit-scrollbar-track {
	background: rgba(@primary-color, 0.5);
	border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb {
	background: @tab-gradient;
	border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
	background: #555;
  }

  .filterby-heading{
	  h3{
		  margin-bottom: 3px;
	  }
  }