@import "../../../assets/less/_variables.less";

.sidebar-inner {
	background: @secondary-color;
	width: 100%;
	max-width: 300px;
	height: calc(100vh - 95px);
	padding: 40px 30px;
	border-right: 1px solid #404040;
	transition: all ease-in-out 0.4s;
	min-height: 100%;
	.sidebar-menu {
		ul {
			list-style-type: none;
			padding-left: 0;
			li {
				margin-bottom: 15px;
				a {
					color: @text-white;
					padding: 10px 18px;
					display: flex;
					align-items: center;
					border-radius: 40px;
					opacity: 0.5;
					font-size: 16px;
					@media screen and (max-width:1366px) {
						font-size: 14px;
					}
					.icon {
						fill: @grey-color;
						margin-right: 10px;
						width: 18px;
					}
					&.selected {
						background: @menu-active;
						font-weight: 600;
						opacity: 1;
					}
				}
			}
		}
		.sidebar-icon {
			width: 20px;
			height: 20px;
			margin-right: 8px;
		}
	}
	.sidebar-bottom {
		border-top: 1px solid @border-color-base;
		margin-top: 5.5rem;
		padding-top: 2.5rem;
		a {
			display: flex;
			align-items: center;
			color: @text-color-primary;
			margin-bottom: 1.5rem;
			margin-left: 1rem;
			font-weight: 600;
			@media screen and (max-width:1280px) {
				font-size: 14px;
			}
			.icon {
				width: 24px;
				fill: @text-color-primary;
				margin-left: 5px;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.ant-layout-sider{
	&.capx-sider {
		.ant-btn {
			&.menu-link {
				background: @menu-active;
				border: 1px solid @border-color-base;
				width: 24px;
				height: 24px;
				border-radius: 24px;
				position: absolute;
				right: 0;
				top: 20px;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				right: -12px;
				z-index: 2;
				.icon {
					width: 6px;
					fill: @text-white;
				}
				&:hover,
				&:focus {
					background: @menu-active;
					border-color: @white;
				}
			}
		}
		@media screen and (min-width:1241px) {
			&.ant-layout-sider-collapsed {
				.sidebar-inner {
					padding-left: 10px;
					padding-right: 10px;
					text-align: center;
					.sidebar-menu {
						li {
							a {
								font-size: 0;
								padding: 5px;
								text-align: center;
								.icon {
									margin: auto;
								}
							}
						}
					}
					.sidebar-bottom {
						a {
							font-size: 9px;
							margin-left: 0;
							.icon {
								width: 18px;
								margin-left: 2px;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width:1240px) {
			position: fixed;
			left: -230px;
			top: 0;
			min-height: calc(100vh - 95px);
			top: 65px;
			z-index: 3;
			min-width: 230px !important;
			max-width: 230px !important;
			width: 230px !important;
			.ant-btn{
				&.menu-link {
					right: -30px;
				}
			}
			&.ant-layout-sider-collapsed {
				left: 0;
				.ant-btn{
					&.menu-link {
						right: -10px;
					}
				}
			}
		}
	}
}
