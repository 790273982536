@import "../../../assets/less/_variables.less";

.ant-layout-header {
	&.header {
		background: @secondary-color;
		color: @white;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid @border-color-base;
		.logo {
			display: flex;
			align-items: center;
			.icon {
				width: 135px;
			}
		}
		.top-bar-right {
			font-size: 26px;
			height: 100%;
			display: flex;
			align-items: center;
			.notifications {
				border-right: 1px solid @border-color-base;
				height: 38px;
				margin-right: 30px;
				padding-right: 30px;
				display: flex;
				align-items: center;
				.bell-icon {
					position: relative;
					height: 100%;
					display: flex;
					align-items: center;
					cursor: pointer;
					span {
						width: 12px;
						height: 12px;
						border-radius: 12px;
						background: @red-color;
						display: block;
						position: absolute;
						top: 4px;
						right: -5px;
					}
					.icon {
						fill: @text-white;
					}
				}
			}
			.ant-select {
				&.coin-select {
					width: 9rem;
					.ant-select-selector {
						padding: 0 10px;
						height: 34px;
						line-height: 34px;
						background: @select-bg-primary;
						border: 1px solid @border-color-base;
						color: @text-white;
						font-size: @font-size-base;
						border-radius: 8px;
						.select-inner {
							display: flex;
							align-items: center;
							color: @text-white;
							.svg-icon {
								margin-right: 8px;
								display: flex;
								align-items: center;
								.svg-icon-inner{
									display: flex;
									align-items: center;
								}
							}
							&.ant-select-item-option-active,
							.ant-select-item-option-selected {
								background: #1d282f;
							}
						}
						.ant-select-selection-item {
							line-height: 34px;
						}
					}
					.ant-select-arrow {
						svg {
							fill: @text-white;
							width: 9px;
						}
					}
				}
			}
			.wallet-address {
				margin-left: 10px;
				.ant-btn {
					display: flex;
					flex-direction: row-reverse;
					align-items: center;
					border: 1px solid @border-color-base;
					background: @dark-bg;
					padding: 0 10px;
					height: 34px;
					border-radius: 8px;
					color: @text-white;
					span {
						height: 34px;
						line-height: 34px;
						border-right: 1px solid @border-color-base;
						margin-right: 10px;
						padding-right: 10px;
					}
					.icon {
						fill: @success-color;
					}
				}
			}
		}
	}
}

.ant-select-dropdown {
	&.coin-select-dropdown {
		border: 1px solid @border-color-base;
		background-color: @select-bg-primary;
		padding: 0;
		.ant-select-item {
			.select-inner {
				display: flex;
				align-items: center;
				color: @text-white;
				font-weight: normal;
				.svg-icon {
					margin-right: 8px;
					display: flex;
					align-items: center;
					.svg-icon-inner {
						display: flex;
						align-items: center;
					}
				}
			}
			&.ant-select-item-option-selected {
				background: @border-color-base;
				color: @text-muted;
			}
			&.ant-select-item-option-active {
				background: @select-bg-secondary;
			}
		}
	}
}
