@import "../../../../assets/less/_variables.less";

.action-btn {
	background: @btn-gradient;
	color: @secondary-color;
	font-weight: bold;
	span {
		color: @secondary-color;
	}
}
