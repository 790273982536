@import "../../assets/less/_variables.less";

.lendborrow-wrapper {
	display: flex;
	width: 100%;
	.lendborrow-left {
		width: 100%;
		padding-right: 50px;
		.groupwith-select {
			display: flex;
			.ant-input {
				border-radius: 8px 0 0 8px;
				border-right: 0;
				@media screen and (max-width:1366px) {
					height: 36px;
					line-height: 36px;
				}
			}
			.ant-select {
				.ant-select-selector {
					border-radius: 0 8px 8px 0;
				}
			}
		}
		.loanassets-select {
			.ant-btn {
				height: 100%;
				height: 40px;
				color: @text-white;
				border-radius: 0 8px 8px 0;
				padding: 0 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-color: @border-dark-green;
				border-left: 0;
				.icon {
					width: 20px;
					margin-right: 4px;
				}
			}
		}
		.loanassets-group {
			.ant-input{
				@media screen and (max-width:1366px) {
					height: 36px;
				}
			}
			.ant-btn {
				height: 100%;
				height: 40px;
				color: @text-color-secondary;
				border-radius: 0 8px 8px 0;
				padding: 0 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-color: @border-dark-green;
				border-left: 0;
				pointer-events: none;
				@media screen and (max-width:1366px) {
					height: 36px;
				}
				.icon {
					width: 20px;
					margin-right: 4px;
				}
			}
		}
		.insufficient-loan-error {
			background: #431e1e;
			border: 1px solid #932e2e;
			border-radius: 7px;
			height: 30px;
			line-height: 30px;
			color: @text-white;
			padding: 0 10px;
			font-weight: 400;
			font-size: @font-size-base - 1;
			display: inline-block;
			margin-top: 5px;
			.icon {
				fill: @text-white;
				margin-right: 6px;
				margin-bottom: -1px;
			}
		}
		.fetching-details-box {
			background: @menu-active;
			border: 1px solid rgb(40, 40, 91);
			border-radius: 7px;
			height: 30px;
			line-height: 30px;
			color: @text-white;
			padding: 0 10px;
			font-weight: 400;
			font-size: @font-size-base - 1;
			display: inline-block;
			margin-top: 5px;
			.icon {
				fill: @text-white;
				margin-right: 6px;
				margin-bottom: -1px;
			}
		}
		.lb-label {
			color: @text-color-secondary;
			font-weight: 600;
			display: block;
			margin-bottom: 3px;
			@media screen and (max-width: 1366px) {
				font-size: 12px;
			}
			.tooltip-icon {
				fill: @tooltip-icon;
				display: inline-block;
				margin-bottom: -2px;
				margin-left: 4px;
				width: 14px;
			}
			.align-right {
				float: right;
			}
		}
		.slider-capx {
			.ant-slider-mark {
				width: calc(100% - 15px);
				left: 4px;
				.ant-slider-mark-text {
					font-size: 0;
					margin-top: 5px;
					&.ant-slider-mark-text-active {
						color: @text-color-secondary;
					}
				}
			}
			.ant-slider-rail {
				background-color: @text-color-secondary;
			}
			.ant-slider-track {
				background: @success-color;
			}
			.ant-slider-step {
				position: initial;
				.ant-slider-dot {
					width: 3px;
					height: 18px;
					background: @text-color-secondary;
					border-radius: 50%;
					border-radius: 3px;
					border: 0;
					&.ant-slider-dot-active {
						background: @success-color;
					}
				}
			}
			.ant-slider-handle {
				width: 24px;
				height: 24px;
				border-radius: 24px;
				margin-top: -13px;
				border: 0;
				background: @success-color;
			}
			&.ant-slider-disabled{
				pointer-events: none;
			}
		}
	}
	.lendborrow-right {
		background: @primary-color;
		border-radius: 7px;
		border: 1px solid @border-color-base;
		width: 100%;
		max-width: 500px;
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
		@media screen and (max-width: 1440px) {
			max-width: 420px;
		}
		@media screen and (max-width: 1280px) {
			max-width: 374px;
		}
		.summary-head {
			border-bottom: 1px solid @border-color-base;
			margin: 0 24px 15px;
			padding: 15px 0;
			@media screen and (max-width: 1366px) {
				padding: 12px 0;
			}
			h3 {
				font-size: 22px;
				margin-bottom: 0;
				@media screen and (max-width: 1440px) {
					font-size: 20px;
				}
				@media screen and (max-width: 1366px) {
					font-size: 19px;
				}
				@media screen and (max-width: 1280px) {
					font-size: 18px;
				}
			}
			p {
				font-size: 14px;
				margin-bottom: 0;
				color: @text-color-secondary;
				@media screen and (max-width: 1440px) {
					font-size: 14px;
				}
				@media screen and (max-width: 1366px) {
					font-size: 13px;
				}
				@media screen and (max-width: 1280px) {
					font-size: 12px;
				}
			}
		}
		.summary-content {
			ul {
				padding-left: 0;
				li {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 3px 24px;
					width: 100%;
					font-size: 15px;
					color: @text-white;
					@media screen and (max-width:1366px) {
						font-size: 13px;
					}
					label {
						font-weight: normal;
					}
					span {
						font-weight: bold;
					}
				}
			}
		}
		.summary-footer {
			display: flex;
			align-items: center;
			padding: 12px 24px;
			border-top: 1px solid @border-color-base;
			margin-top: auto;
			background: #172026;
			border-radius: 0 0 7px 7px;
			@media screen and (max-width: 1366px) {
				padding: 8px 15px;
			}
			.left {
				border-right: 1px solid @border-color-base;
				margin-right: 30px;
				padding-right: 20px;
				min-width: 140px;
				@media screen and (max-width: 1366px) {
					line-height: 1.1;
				}
				@media screen and (max-width: 1280px) {
					margin-right: 20px;
    				padding-right: 10px;
					min-width: 130px;
				}
				small {
					color: @text-color-secondary;
					&:last-child {
						font-weight: 500;
					}
				}
				h2 {
					margin-bottom: 0;
					font-size: 30px;
					font-weight: 600;
					line-height: 1;
					@media screen and (max-width: 1440px) {
						font-size: 28px;
					}
					@media screen and (max-width: 1366px) {
						font-size: 25px;
					}
				}
			}
			.right {
				width: 100%;
				.ant-btn {
					width: 100%;
					padding: 0 15px;
					@media screen and (max-width: 1280px) {
						padding: 0 15px;
						font-size: 14px;
					}
				}
			}
		}
	}
}

.best-offer-link {
	font-weight: normal;
	padding: 0;
	color: @text-color-secondary;
	font-size: @font-size-base - 1;
	line-height: 1.1;
	span {
		border-bottom: 1px solid @text-color-secondary;
		color: @text-color-secondary;
	}
}

.disabled-button {
	opacity: 0.5;
	pointer-events: none;
}

.pulse-animate {
	animation: pulse 3.5s infinite ease-in-out;
}

// Loading pulse animations
@keyframes pulse {
	0% {
		opacity: 0.2;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
}

@-o-keyframes pulse {
	0% {
		opacity: 0.2;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
}

@-moz-keyframes pulse {
	0% {
		opacity: 0.2;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
}

@-webkit-keyframes pulse {
	0% {
		opacity: 0.2;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
}
