@import "../../assets/less/_variables.less";

.main-container-bp {
	width: 100%;
	max-width: 800px;
	margin: auto;
	display: flex;
	align-items: center;
	height: calc(100vh - 115px);
	.main-container-bp-inner{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		padding: 40px;
		border-radius: 2rem;
		margin: auto;
		background: linear-gradient(
			270.08deg,
			rgba(132, 214, 164, 0.3) 0.02%,
			rgba(18, 28, 32, 0.3) 117.26%
		);
		width: fit-content;
		height: fit-content;
		text-align: center;
	}

	.header-bp {
		color: white;
		font-size: 18px;
		text-align: center;

	}
	.breakpoint-image{
		width: 80%;
	}
	.connect-btn {
		color: black;
		cursor: pointer;
		background-color: white;
		border-radius: 12px;
		padding: 1rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		.metamask-icon {
			margin-right: 1rem;
			width: 1.5rem;
		}
	}
	.breakpoint-icon {
		width: 100px;
		margin: auto;
	}
}

.closeIcon {
	margin-top: 1rem;
}

.ant-notification-notice-with-icon {
	display: grid;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
}
