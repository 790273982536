@import "../../../assets/less/_variables.less";

.test-class {
	display: flex;
}

.ScrollbarsCustom {
	&.dashboard-scroll{
		height: calc(100vh - 510px) !important;
		@media screen and (max-width:1440px) {
			height: calc(100vh - 458px) !important;
		}
		@media screen and (max-width:1366px) {
            height: calc(100vh - 433px) !important;
        }
	}
}